export const FOOTER = Object.freeze({
  items: [
    {
      id: 1,
      title: 'STORAGE SERVICES',
      className: '',
      children: [
        {
          id: 1,
          url: '/residential-storage',
          label: 'Residential Storage Solution'
        },
        {
          id: 2,
          url: '/business-storage',
          label: 'Business Storage Solution'
        },
        {
          id: 3,
          url: '/moving-storage-solution',
          label: 'Moving Storage Solution'
        },
        {
          id: 4,
          url: '/college-storage-services',
          label: 'College Storage Services'
        },
        {
          id: 5,
          url: '/drop-off-storage-locations',
          label: 'Drop Off The Storage'
        },
        {
          id: 6,
          url: '/pickup-and-storage',
          label: 'Pickup From Storage'
        },
        {
          id: 7,
          url: '/luggage-storage',
          label: 'Luggage Storage Services'
        },
        {
          id: 8,
          url: '/box-storage',
          label: 'Moving Box Storage'
        },
        {
          id: 9,
          url: '/golf-club-storage',
          label: 'Golf Club Storage'
        },
        {
          id: 10,
          url: '/snowboard-storage',
          label: 'Snowboard Storage'
        },
        {
          id: 11,
          url: '/ski-storage',
          label: 'Ski Gear Storage'
        }
      ]
    },
    {
      id: 2,
      title: 'SHIPPING SERVICES',
      className: '',
      children: [
        {
          id: 1,
          url: '/domestic-shipping-services',
          label: 'Domestic Shipping Services'
        },
        {
          id: 2,
          url: '/international-shipping-services',
          label: 'International Shipping Services'
        },
        {
          id: 3,
          url: '/shipping-to-storage',
          label: 'Shipping To Storage'
        },
        {
          id: 4,
          url: '/luggage-shipping',
          label: 'Luggage Shipping'
        },
        {
          id: 5,
          url: '/moving-box-shipping',
          label: 'Moving Box Shipping'
        },
        {
          id: 6,
          url: '/',
          label: 'Golf Club Shipping'
        },
        {
          id: 7,
          url: '/snowboard-shipping',
          label: 'Snowboard Shipping'
        },
        {
          id: 8,
          url: '/ski-gear-shipping',
          label: 'Ski Gear Shipping'
        },
        {
          id: 9,
          url: '/college-student-shipping',
          label: 'College Student Shipping'
        },
        {
          id: 10,
          url: '/',
          label: 'Summer Camp Shipping'
        }
      ]
    },
    {
      id: 3,
      title: 'KNOWLEDGE BASE',
      className: '',
      children: [
        {
          id: 1,
          url: '/how-storage-solutions-work',
          label: 'How Storage Services Works'
        },
        {
          id: 2,
          url: '/',
          label: 'How Shipping Services Works'
        },
        {
          id: 3,
          url: '/',
          label: 'How To Attach Shipping Labels'
        },
        {
          id: 4,
          url: '/',
          label: 'Use Correct Packing Material'
        },
        {
          id: 5,
          url: '/',
          label: 'Shipping Weight and Sizes'
        },
        {
          id: 6,
          url: '/',
          label: 'Cost Saving Tips on Shipping '
        },
        {
          id: 7,
          url: '/',
          label: 'Shipping Insurance'
        },
        {
          id: 8,
          url: '/',
          label: 'What We Ship'
        },
        {
          id: 9,
          url: '/',
          label: 'What We Store'
        },
        {
          id: 10,
          url: '/what-cant-ship',
          label: 'Prohibited Items to Ship',
          className: 'text-red-500 font-semibold'
        },
        {
          id: 11,
          url: '/what-cant-ship',
          label: 'Prohibited Items to Store',
          className: 'text-red-500 font-semibold'
        }
      ]
    },
    {
      id: 4,
      title: 'QUICK LINKS',
      className: '',
      children: [
        {
          id: 1,
          url: '/',
          label: 'My Rewards'
        },
        {
          id: 2,
          url: '/order-packing-material',
          label: 'Order Packing Material'
        },
        {
          id: 3,
          url: '/luggage-tracking',
          label: 'Track Your Shipping'
        },
        {
          id: 4,
          url: '/',
          label: 'Drop off Location'
        },
        {
          id: 5,
          url: '/luggage-and-question',
          label: 'General FAQ'
        }
      ]
    },
    {
      id: 5,
      title: 'COMPANY',
      className: '',
      children: [
        {
          id: 1,
          url: '/about-us',
          label: 'About Us'
        },
        {
          id: 2,
          url: '/contact',
          label: 'Contact Us'
        },
        {
          id: 3,
          url: '/',
          label: 'News & Press'
        },
        {
          id: 4,
          url: '/',
          label: 'Shipping Partners'
        },
        {
          id: 5,
          url: '/',
          label: 'Business program'
        },
        {
          id: 6,
          url: '/',
          label: 'Partner login'
        },
        {
          id: 7,
          url: '/privacy-policy',
          label: 'Privacy Policy'
        },
        {
          id: 8,
          url: '/terms-and-conditions',
          label: 'Terms of Use'
        }
      ]
    }
  ]
})
