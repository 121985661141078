import AuthCheck from '@/components/AuthCheck'
import ToasterInfoMessages from '@/components/common/ToasterInfoMessages'
import MainHeader from '@/layouts/header/MainHeader'
import Footer from '@/layouts/v1/footer'

const Layout = ({ children }) => {
  return (
    <>
      <ToasterInfoMessages />
      <AuthCheck>
        <MainHeader />
      </AuthCheck>
      <main className={'sas-main-content-wr overflow-hidden'}>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
